import china from './sources/china.json';
// import europe from './sources/europe.json';
import japan from './sources/japan.json';
import korea from './sources/korea.json';
import uk from './sources/uk.json';
import us from './sources/us.json';

export default {
  '中国': china,
  //'欧洲': europe,
  '日本': japan,
  '韩国': korea,
  '英国': uk,
  '美国': us,
};
